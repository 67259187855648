<template>
    <v-row justify="center" id="modal-page">
        <v-dialog :content-class="nestModal ? 'd-none' : ''"
            v-model="open" scrollable
            persistent
            :max-width="modalData.width || '800'"
            @click:outside="!modalLoading && !loading && $emit('close')"
        >
            <div class="background lighten-5 overflow-hidden">
                <v-form @submit.prevent="modalData.saveBtnTxt && $emit('save')">
                    <div class="text-h6 d-flex primary text-white text-capitalize pa-6 pt-3 pb-3 position-sticky">                    
                        <div class="mr-auto font-weight-bold">{{modalData.title}}</div>
                        <v-btn class="pa-0 " type="button" :disabled="modalLoading || loading" rounded fab x-small plain @click="$emit('close')">
                            <v-icon color="white" dark>mdi-close</v-icon>
                        </v-btn>
                    </div>
                    <vue-perfect-scrollbar :style="{'height': modalData.height || 'auto', 'max-height': modalData.maxHeight || 'auto'}" class="modal-container" :settings="{'maxScrollbarLength': 60}">
                        <div class="pa-7">
                            <slot name="modalContent"></slot>
                        </div>
                    </vue-perfect-scrollbar>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-3 pr-7" v-if="modalData.saveBtnTxt">
                        <v-spacer></v-spacer>
                        <v-btn class="font-weight-bold" type="submit" :disabled="modalLoading || loading"
                            color="#5CBBF6"
                            text
                        >
                            {{ modalData.saveBtnTxt }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </div>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: "Modal",
  props: ["open", "modalData", "loading", "nestModal"],
  emits: ['close', 'save'],
  computed: {
    ...mapGetters(["modalLoading"]),
  }
};
</script>

<style lang="scss">
.modal-container {
  position: relative;
  margin: auto;
}
</style>
